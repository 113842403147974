import  { atom, selector } from 'recoil';

export const controlOptions = atom({
    key: 'controlOptions',
    default: {
        isRunning: false,
        speed: 10,
    },
});

export const currentStakeState = atom({
    key: 'currentStakeState',
    default: {},
});

export const caughtSymbolsState = atom({
    key: 'caughtSymbolsState',
    default: [],
});

export const allPassedDotsState = atom({
    key: 'allPassedDotsState',
    default: [],
});

export const dotsState = atom({
    key: 'dotsState',
    default: [],
});

export const symbolsState = atom({
    key: 'symbolsState',
    default: {
        isRunning: false,
        symbols: [],
        pickedSymbols: [],
    },
});

export const individualScoreState = atom({
    key: 'individualScoreState',
    default: {
        message: '',
        class: '',
        x: 0,
        y: 0,
    },
    default: '',
});

export const itterationState = atom({
    key: 'itterationState',
    default: 0,
})

export const scoreState = atom({
    key: 'scoreState',
    default: 0,
});

export const timerState = atom({
    key: 'timerState',
    default: 60,
});
